import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I've encountered this bug enough times that I figured it was time to write a quick post about it. If you've looked into `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/app_and_environment/scenes"
      }}>{`multiple window support`}</a>{` for your app in iOS 13, you might know that a lot of the setup you used to have to do in the app delegate now lives in the scene delegate instead.`}</p>
    <p>{`However, multiple times now, I've thought I've set everything up correctly (scene delegate class implemented, configuration with `}<inlineCode parentName="p">{`delegateClass`}</inlineCode>{` set properly, correct Info.plist setup, etc), only to get a black screen on launch.`}</p>
    <p>{`The secret is this: prior to iOS 13, it was common to set up your `}<inlineCode parentName="p">{`UIWindow`}</inlineCode>{` with the bounds of the screen:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// Does not work in iOS 13+`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`window = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIWindow`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UIScreen.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`main`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`bounds`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`This breaks when using the UISceneSession system. Instead, you have to initialize the window with the scene itself, in your `}<inlineCode parentName="p">{`scene(scene:willConnectTo:options:)`}</inlineCode>{` method:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`window = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIWindow`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`windowScene`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: windowScene)`}</span></span></code></pre>
    <p>{`This has generally been the missing piece I forgot, and ends up resolving the black screen issue. `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGSystemColorComparison/blob/master/NGSystemColorComparison/SceneDelegate.swift#L24"
      }}>{`Here's a concrete example`}</a>{`.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      